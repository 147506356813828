import { ethers } from "ethers";

export const getContractProvider = async () => {
  const ADDRESS = process.env.REACT_APP_ADDRESS;
  const contractAbi = require("../contract/abi.abi.json");
  const provider = new ethers.providers.InfuraProvider(
    process.env.REACT_APP_TEST_MINT ? "sepolia" : "homestead"
  );
  const icoContract =
    ADDRESS && new ethers.Contract(ADDRESS, contractAbi, provider);

  return icoContract;
};
