import React, { StrictMode } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import StakeTemplate from "../components/StakeTemplate";
import styles from "../styles/stake.module.css";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};

const Stake = () => {
  return (
    <StrictMode>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className={styles.ContainerStake}>
         
          <StakeTemplate />
        </div>
      </Web3ReactProvider>
    </StrictMode>
  );
};
export default Stake;
