import React from "react";
import { utils } from "ethers";
import ConnectMetamask from "./ConnectMetamask";

const defaultState = {
  mintAmount: 1,
};

export default class MintWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  incrementMintAmount() {
    if (this.props.maxTx > this.props.balance.toNumber())
      this.setState({
        mintAmount: Math.min(
          this.props.maxTx - this.props.balance.toNumber(),
          this.state.mintAmount + 1
        ),
      });
  }

  decrementMintAmount() {
    this.setState({
      mintAmount: Math.max(1, this.state.mintAmount - 1),
    });
  }

  getPrice(mintAmount) {
    let amount = mintAmount;
    if (this.props.maxTx === this.props.balance.toNumber()) amount = 1;
    let maxFree = this.props.plFree;
    if (this.props.isAllowlist) {
      maxFree = this.props.alFree;
    }

    if (!(this.props.claimed >= maxFree)) {
      if (mintAmount <= maxFree - this.props.claimed) {
        amount = 0;
      } else {
        amount = mintAmount - (maxFree - this.props.claimed);
      }
    }

    return utils.formatEther(this.props.salePrice.mul(amount));
  }

  async mint(amount = this.state.mintAmount) {
    if (amount !== this.state.mintAmount) {
      this.setState({ mintAmount: amount });
    }
    if (!this.props.isPaused) {
      let price = this.getPrice(amount);
      await this.props.mintTokens(amount, price);
      return;
    }
  }

  isMaxTx(quantity) {
    return this.props.balance.toNumber() + quantity > this.props.maxTx;
  }

  isSoldOut() {
    return (
      this.props.maxSupply !== 0 &&
      this.props.totalSupply >= this.props.maxSupply
    );
  }

  textButtonMint() {
    if (
      (this.props.isWalletConnected || !this.props.loading) &&
      !this.props.isPaused
    ) {
      if (this.isSoldOut()) {
        return "Sold Out";
      } else {
        if (this.props.isAllowlist) {
          return "Allowlis Mint";
        }
        return "Mint";
      }
    }
    return "Not open yet";
  }

  render() {
    return (
      <>
        <div className="px-0 pt-4 w-full relative font-minecraftia ">
          <div className="flex flex-col space-y-2 w-full">
            <div className="flex items-center justify-center flex-col">
              <p className="text-5xl font-bold">
                {this.props.totalSupply}/{this.props.maxSupply}
              </p>
            </div>
            <div className="flex flex-col gap-1 sm:gap-4 mx-auto">
              <div className="flex w-full items-center justify-between mx-auto">
                <button
                  type="button"
                  className="h-10 w-10 bg-[#171718] hover:bg-[#575757] border rounded flex items-center justify-center"
                  onClick={() => this.decrementMintAmount()}
                >
                  <span className="text-3xl text-white -mt-[5px] font-mono">
                    -
                  </span>
                </button>
                <div className="h-10 w-20 mx-4 flex items-center justify-center">
                  <span className="font-bold text-4xl">
                    {this.state.mintAmount}
                  </span>
                </div>
                <button
                  type="button"
                  className="h-10 w-10  bg-[#171718] hover:bg-[#575757] border rounded flex items-center justify-center"
                  onClick={() => this.incrementMintAmount()}
                >
                  <span className="text-3xl text-white -mt-[5px] font-mono">
                    +
                  </span>
                </button>
              </div>
              <p className="mt-4">
                Total: {this.getPrice(this.state.mintAmount)} ETH
              </p>
            </div>

            <div className="grid grid-cols-1 max-w-xs mx-auto justify-between items-center gap-5 font-[Nosifer]">
              <ConnectMetamask
                userAddress={this.props.userAddress}
                isWalletConnected={this.props.isWalletConnected}
                connectWallet={() => this.props.connectWallet()}
                disconnectWallet={() => this.props.disconnectWallet()}
              />

              <button
                className="w-[280px] h-[50px] bg-[linear-gradient(45deg,#b09349_0%,#face02_100%)] hover:bg-[linear-gradient(45deg,#b09349_50%,#face02_100%)] text-white hover:text-white border-none rounded-none hover:shadow-[3px_3px_8px_0_rgba(91,91,91,1)] py-2 font-semibold text-2xl uppercase disabled:bg-[#B7B7B7] disabled:text-[#090909] disabled:hover:cursor-not-allowed"
                disabled={
                  !this.props.isWalletConnected ||
                  this.props.isPaused ||
                  this.props.loading ||
                  this.props.disabled ||
                  this.isSoldOut() ||
                  this.isMaxTx(this.state.mintAmount)
                }
                onClick={() => this.mint()}
              >
                <span className="flex items-center relative h-full w-full opacity-100 justify-center">
                  <span className="flex items-center px-2">
                    {this.textButtonMint()}
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
