import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import WalletChecker from "./pages/WalletChecker";
import Gift from "./pages/Stake";
import Mint from "./pages/Mint";

window.Buffer = window.Buffer || Buffer;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NoPage />} />
        <Route path="/walletchecker" element={<WalletChecker />} />
        <Route path="/free-mint" element={<Mint />} />
        <Route path="/surprise" element={<Gift />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
