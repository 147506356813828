import ReactLoading from "react-loading";

function Loading() {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-75">
      <ReactLoading type="spin" color="#fff" height={50} width={50} />
    </div>
  );
}

export default Loading;