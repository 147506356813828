import React, { useState } from "react";
import styles from "../index.module.css";
import ReactMarkdown from "react-markdown";
import Menu from "../components/Menu";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFrameSrc, setSelectedFrameSrc] = useState(null);
  const [desc, setDesc] = useState(null);
  const [title, setTitle] = useState(null);

  const openModal = (frameSrc, desc, title) => {
    setSelectedFrameSrc(frameSrc);
    setDesc(desc);
    setTitle(title);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedFrameSrc(null);
    setDesc(null);
    setTitle(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Menu></Menu>
      <div className={styles.Content}>
        <div className={styles.largeScreenImage}>
          <img src="/assets/images/bg-home.webp" alt="home"></img>
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame1.webp",
                "Evil Mask NFT is a collection of 4,444 unique Halloween masks, hand-drawn in old-school style to bring the most eerie feeling to the season. Evil Mask is not only a free mint project, but also a special socialfi experience for holders.",
                "EVIL MASK"
              )
            }
            style={{
              top: "8vh",
              left: "1vw",
              width: "45vw",
              height: "54vh",
            }}
          />
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame2.webp",
                "All details in the Evil Mask Collection are hand-drawn in old-school style to bring the most eerie feeling on Halloween. You won't find a more interesting set of paintings than Evil Mask for this Halloween season.",
                "WOLFMAN"
              )
            }
            style={{
              top: "8vh",
              left: "46vw",
              width: "25vw",
              height: "54vh",
            }}
          />

          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame3.webp",
                "The mint date is set for Halloween, and the mint time will be officially announced on Twitter. The collection will be free for both whitelist and public holders, but whitelist holders will get more free slots and priority.",
                "DRACULA"
              )
            }
            style={{
              top: "8vh",
              right: "1vw",
              width: "27vw",
              height: "54vh",
            }}
          />
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame4.webp",
                `Created to combine old-school art and socialfi for Halloween, all holders will be able to choose "Trick or Treat" for a chance to win rewards commensurate with their fear.`,
                "Trick'r Treats"
              )
            }
            style={{
              top: "62vh",
              left: "1vw",
              width: "27vw",
              height: "42vh",
            }}
          />

          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame5.webp",
                "We have prepared many gifts and contests for Halloween. Everyone can participate and can take home the gift that suits them as long as they are ready.",
                "Pumpkin Gift"
              )
            }
            style={{
              top: "62vh",
              left: "28vw",
              width: "23vw",
              height: "42vh",
            }}
          />

          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame6.webp",
                "A large amount of $Treat has been prepared and is ready to be airdropped to everyone. If you are a holder, the amount of $Treat airdropped to you will be commensurate with the number of NFTs you own.",
                "Horrible Clowns"
              )
            }
            style={{
              top: "62vh",
              left: "51vw",
              width: "23vw",
              height: "42vh",
            }}
          />
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame7.webp",
                "If you haven't won a whitelist spot yet, don't worry! There will be final wallet collections on Twitter before the public mint, and there will always be enough space for true supporters. See you all on Halloween for a Free Mint Halloween Socialfi Experience!",
                "Casper"
              )
            }
            style={{
              top: "62vh",
              right: "1vw",
              width: "25vw",
              height: "42vh",
            }}
          />
        </div>

        {/* phone */}
        <div className={styles.smallScreenImage}>
          <img src="/assets/images/home_phone.webp" alt="home-large"></img>
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame1.webp",
                "Evil Mask NFT is a collection of 4,444 unique Halloween masks, hand-drawn in old-school style to bring the most eerie feeling to the season. Evil Mask is not only a free mint project, but also a special socialfi experience for holders.",
                "EVIL MASK"
              )
            }
            style={{
              top: "20vh",
              left: "0",
              width: "58vw",
              height: "32vh",
            }}
          />
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame2.webp",
                "All details in the Evil Mask Collection are hand-drawn in old-school style to bring the most eerie feeling on Halloween. You won't find a more interesting set of paintings than Evil Mask for this Halloween season.",
                "WOLFMAN"
              )
            }
            style={{
              top: "77vh",
              left: "0",
              width: "58vw",
              height: "28vh",
            }}
          />

          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame3.webp",
                "The mint date is set for Halloween, and the mint time will be officially announced on Twitter. The collection will be free for both whitelist and public holders, but whitelist holders will get more free slots and priority.",
                "DRACULA"
              )
            }
            style={{
              top: "10vh",
              right: "0",
              width: "43vw",
              height: "23vh",
            }}
          />
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame4.webp",
                `Created to combine old-school art and socialfi for Halloween, all holders will be able to choose "Trick or Treat" for a chance to win rewards commensurate with their fear.`,
                "Trick'r Treats"
              )
            }
            style={{
              top: "80vh",
              right: "0",
              width: "43vw",
              height: "25vh",
            }}
          />

          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame5.webp",
                "We have prepared many gifts and contests for Halloween. Everyone can participate and can take home the gift that suits them as long as they are ready.",
                "Pumpkin Gift"
              )
            }
            style={{
              top: "52vh",
              left: "0",
              width: "58vw",
              height: "25vh",
            }}
          />

          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame6.webp",
                "A large amount of $Treat has been prepared and is ready to be airdropped to everyone. If you are a holder, the amount of $Treat airdropped to you will be commensurate with the number of NFTs you own.",
                "Horrible Clowns"
              )
            }
            style={{
              top: "33vh",
              right: "0",
              width: "43vw",
              height: "23vh",
            }}
          />
          <div
            className={styles.comicFrame}
            onClick={() =>
              openModal(
                "/assets/images/frame7.webp",
                "If you haven't won a whitelist spot yet, don't worry! There will be final wallet collections on Twitter before the public mint, and there will always be enough space for true supporters. See you all on Halloween for a Free Mint Halloween Socialfi Experience!",
                "Casper"
              )
            }
            style={{
              top: "56vh",
              right: "0",
              width: "43vw",
              height: "24vh",
            }}
          />
        </div>

        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <h2>{title}</h2>
                <button className={styles.modalClose} onClick={closeModal}>
                  X
                </button>
              </div>
              <div className={styles.modalBody}>
                <img src={selectedFrameSrc} alt="Selected Frame" />
                <p>
                  <ReactMarkdown>{desc}</ReactMarkdown>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Home;
