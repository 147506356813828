import React, { StrictMode } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import MintTemplate from "../components/Mint/MintTemplate";
import Menu from "../components/Menu";
import styles from "../styles/mint.module.css";

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  return library;
};
const Mint = () => {
  return (
    <div className={styles.ContainerMint}>
      <Menu></Menu>
      <div className={styles.WalletChecker}>
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalBody}>
              <div>
                <label>
                  <p>
                    The First 100 Max Minters Will Get Airdropped 10 NFTs Each
                    <br></br>
                    <br></br>
                    Please Comment Your Mask On Twitter ( FCFS)
                    <br></br>
                    Thank You For Your Kindness For Supporting The Artist
                    <br></br>
                  </p>
                </label>
                <div className={styles.MintInfo}>
                  <StrictMode>
                    <Web3ReactProvider getLibrary={getLibrary}>
                      <MintTemplate />
                    </Web3ReactProvider>
                  </StrictMode>

                  <div className={styles.linkG}>
                    <a
                      href="https://etherscan.io/address/0x2eb7E1dF897fBca9762146471613cbcC7Abcb460"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/images/etherscan.png"
                        alt="etherscan"
                      ></img>
                    </a>

                    <a
                      href="https://twitter.com/EvilMaskNFT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/assets/images/twitter.png" alt="twitter"></img>
                    </a>

                    <a
                      href="https://opensea.io/collection/evil-mask"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="/assets/images/opensea.png" alt="opensea"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
