import React, { useState } from "react";
import styles from "../styles/walletCheck.module.css";
import Loading from "../components/Loading";
import axios from "axios";
import Menu from "../components/Menu";
import "../index.css";

const WalletChecker = () => {
  const [wallet, setWallet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalSelected, setIsModalSelected] = useState(false);
  const [isModalNotSelected, setIsModalNotSelected] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsModalSelected(false);
    setIsModalNotSelected(false);
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("wallet", wallet);
      axios
        .post(
          `${process.env.REACT_APP_END_POINT}/api/v1/check-wallet`,
          formData
        )
        .then((response) => {
          //   console.log(response.data.data);
          setIsLoading(false);
          if (response.data.status === true) {
            setIsModalSelected(true);
          } else {
            setIsModalNotSelected(true);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const closeModal = () => {
    setIsModalSelected(false);
  };

  const closeModalNot = () => {
    setIsModalNotSelected(false);
  };
  return (
    <div>
      {isLoading && <Loading></Loading>}
      <Menu></Menu>
      <div className={styles.WalletChecker}>
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalBody}>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>
                    <p>
                      Evil Mask NFT Will Be Free Mint On Halloween (Oct 31) The
                      Final White List Will Be Closed Soon
                      <br></br>
                      <br></br>
                      Please Check Wallet To Make Sure You Are On The List
                      <br></br>
                    </p>
                  </label>
                  <div className={styles.checkPart}>
                    <input
                      type="text"
                      value={wallet}
                      onChange={(e) => setWallet(e.target.value)}
                      placeholder="0x..."
                      required
                    />
                    <button type="submit">CHECK WALLET</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isModalSelected && (
        <div className="modal-overlay ">
          <div className="modal-content selected">
            <div className="modal-header">
              <h2> Whitelisted!</h2>
              <button className="modal-close" onClick={closeModal}>
                x
              </button>
            </div>
            <div className="modal-body">
              <p>
                Good Going! WhiteListed!
                <br />2 Free Mint Spots Are Ready For You
              </p>
            </div>
          </div>
        </div>
      )}

      {isModalNotSelected && (
        <div className="modal-overlay ">
          <div className="modal-content notselected">
            <div className="modal-header">
              <h2>Not WL!</h2>
              <button className="modal-close" onClick={closeModalNot}>
                x
              </button>
            </div>
            <div className="modal-body">
              <p>
                Not That Luck! Not Whitelisted
                <br />
                There Maybe Last Chance WL on Twitter
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default WalletChecker;
