import React, { useState } from "react";
import styles from "./menu.module.css";

function Menu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <div className={styles.app}>
        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <a href="/" className={styles.navLink}>
                Home
              </a>
            </li>

            <li className={styles.navItem}>
              <a href="/walletchecker" className={styles.navLink}>
                Checker
              </a>
            </li>

            <li className={styles.navItem}>
              <a href="/free-mint" className={styles.navLink}>
                Mint
              </a>
            </li>

            <li className={styles.navItem}>
              <a href="/surprise" className={styles.navLink}>
                Trick Or Treat
              </a>
            </li>

            <li className={styles.navItemTwitter}>
              <a
                href="https://twitter.com/EvilMaskNFT"
                className={styles.navLink}
              >
                <img src="/assets/images/twitter.png" alt="twitter"></img>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      {/* mobile */}
      <div className={styles.m_app}>
        <button className={styles.m_menuToggle} onClick={toggleMenu}>
          ☰ Menu
        </button>
        <nav className={`${styles.m_nav} ${isMenuOpen ? styles.open : ""}`}>
          <ul className={styles.m_navList}>
            <li className={styles.m_navItem}>
              <a href="/" className={styles.m_navLink}>
                Home
              </a>
            </li>
            <li className={styles.m_navItem}>
              <a href="/walletchecker" className={styles.m_navLink}>
                Checker
              </a>
            </li>
            <li className={styles.navItem}>
              <a href="/free-mint" className={styles.navLink}>
                Mint
              </a>
            </li>

            <li className={styles.navItem}>
              <a href="/surprise" className={styles.navLink}>
                Trick Or Treat
              </a>
            </li>

            <li className={styles.navItem}>
              <a
                href="https://twitter.com/EvilMaskNFT"
                className={styles.navLink}
              >
                Twitter
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Menu;
