/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../utils/connectors";
import { switchChain } from "../utils/switchChain";
import { getContractProvider } from "../utils/getContractProvider";
import config from "../config";
import ConnectMetamask from "./Mint/ConnectMetamask";
import Menu from "./Menu";
import "../index.css";

const contractAbi = require("../contract/abi.abi.json");

const StakeTemplate = () => {
  const { account, activate, deactivate, active, library } = useWeb3React();
  const [contract, setContract] = useState();
  const [userAddress, setUserAddress] = useState(null);
  const [walletOfOwner, setWalletOfOwner] = useState(null);
  const [urlimg, setUrlImage] = useState(null);
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState(null);

  const [isModalSelected, setIsModalSelected] = useState(false);

  useEffect(() => {
    const init = async () => {
      setContract(await getContractProvider());
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await connectWallet();
    };
    if (!active) {
      init();
    }
  }, []);

  useEffect(() => {
    if (account) {
      setUserAddress(account);
    }
  }, [account]);

  useEffect(() => {
    if (userAddress) {
      const refresh = async () => {
        await refreshContractStateHasAddress();
      };
      refresh();
    }
  }, [userAddress]);

  useEffect(() => {
    if (library) {
      setContract(
        new ethers.Contract(
          process.env.REACT_APP_ADDRESS,
          contractAbi,
          library.getSigner()
        )
      );
    }
  }, [library]);

  const refreshContractStateHasAddress = async () => {
    setWalletOfOwner(await contract.walletOfOwner(userAddress));
  };

  const connectWallet = async () => {
    await activate(
      injected,
      async (error) => {
        const network = config.unsupportedChainSetup[config.chainId];
        const hasSetup = await switchChain(
          network ?? {
            chainId: `0x${parseInt(config.chainId.toString()).toString(16)}`,
          }
        );
        if (hasSetup) {
          await activate(injected, async () => {
            console.log(`${error.message}`);
          });
        } else {
          alert(`Unable to connect to required network ${config.chainId}`);
        }
      },
      false
    );
  };

  const handleActive = (index) => {
    if (userAddress == null) {
      alert("Please connect the wallet on the right side of the screen!");
    } else if (walletOfOwner?.length > 0) {
      const param = {
        wallet: userAddress,
        index: Number(index),
      };

      fetch(
        `${process.env.REACT_APP_END_POINT}/api/v1/evil-mask/trick-or-treat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(param),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === false) {
            alert(data.message);
          } else {
            console.log(data.data);
            setContent(data.data.content);
            setUrlImage(data.data.url);
            setIsModalSelected(true);
            if (index === 1) {
              setTitle("Trick");
            } else {
              setTitle("Treat");
            }
          }
        })
        .catch((error) => {
          alert("ERR! " + error);
        });
    } else {
      alert(
        "YOU ARE NOT HOLDER YET, You Have To Own At Least 1 NFTs To Join The Party"
      );
    }
  };

  const closeModal = () => {
    setContent(null);
    setUrlImage(null);
    setTitle(null);
    setIsModalSelected(false);
  };

  return (
    <div>
      <Menu></Menu>
      <div className=" connect-wallet w-full text-black  rounded-xl font-semibold text-2xl uppercase hover:text-white disabled:bg-[#B7B7B7] disabled:text-[#090909] disabled:hover:cursor-not-allowed">
        <ConnectMetamask
          userAddress={userAddress}
          isWalletConnected={active}
          connectWallet={() => connectWallet()}
          disconnectWallet={() => deactivate()}
        />
      </div>
      <div className="content">
        <div className="button-center">
          <button
            title={
              walletOfOwner?.length > 0
                ? ""
                : "YOU ARE NOT HOLDER YET, You Have To Own At Least 1 NFTs To Join The Party"
            }
            onClick={() => handleActive(1)}
          >
            Trick
          </button>

          <button
            title={
              walletOfOwner?.length > 0
                ? ""
                : "YOU ARE NOT HOLDER YET, You Have To Own At Least 1 NFTs To Join The Party"
            }
            onClick={() => handleActive(2)}
          >
            Treat
          </button>
        </div>

        {isModalSelected && (
          <div className="modal-overlay ">
            <div className="modal-content selected">
              <div className="modal-header">
                <h2>{title}</h2>
                <button className="modal-close" onClick={closeModal}>
                  x
                </button>
              </div>
              <div className="modal-body">
                <img src={urlimg} alt="trick or treat" />
                <p>{content}</p>
              </div>
            </div>
          </div>
        )}

        {/* {walletOfOwner?.length > 0 ? (
          <>You own our NFT.</>
        ) : (
          <>You must own our NFT to access.</>
        )} */}
      </div>
    </div>
  );
};

export default StakeTemplate;
